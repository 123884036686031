.main{
   width: 60%;
   height: 800px;
   text-align: center;
   margin-left: 280px;
   z-index: -1;
   border-radius: 73px;
   background:linear-gradient(225deg, #2E1760, #291556); 
   box-shadow:  -42px 42px 34px #25134e, 
             42px -42px 34px #371b72;
}
.main h1{
    padding-top: 50px;
}

.main p{
    padding:20px;
    font-size: 20px;
}
.who{
    text-decoration: underline;
    font-size:28px;
}

@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px) {
    .main{
        width: 100%;
        height: auto;
        margin-left: 0;
    }
    .main p{
        font-size: 18px;
    }
    .who {
        font-size: 25px;
    }
    
}