.video_content{
    display:flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding:15px;
}
.video_main{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.video_area{
    display: flex;
    flex-direction: column;
    align-items: center ;
    justify-content: space-evenly;
    width: 400px;
    margin-bottom: 50px;
    border-radius: 50px;
    background:#2E1760;
    box-shadow:  42px 42px 44px #281454, 
             -42px -42px 44px #341a6c;
}

.video_area> p{
    margin-bottom: 20px;
    width: 60% ;
    font-size: 1rem;
}
.video_player{
    width:40%;
    height: 50%;

}

@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px) {
    .video_area{
        width: 100%;
        flex-direction: column;
        align-items: center;
        margin-right: 5px;
        margin-left:0px
    } 
    .video_area> h3{
        width: 280px;
    }
    .video_area .video_player{
        width: 40%;
    }
}

.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  