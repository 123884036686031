
.page-link{
    color:#2E1760;
}

.pagination{
    display: flex;
    list-style-type: none;
    width: 30px;
    margin-bottom: 20px;
    
}

.pagination a{
    margin: 5px;
    width:30px;
    transition: width 2s;
}
.page-item{
    background-color: white;
    margin: 5px;
    padding: 5px;
    border-radius: 50%;
}

.pagination a:hover{
    text-decoration: underline;     
    text-decoration-color: white;
    cursor: pointer;
    width: 60px;
}
@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px) {
    .pagination{
        width: 100%;
        text-align: center;
    }
}