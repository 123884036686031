.header{
    display: flex;
    justify-content: space-between;
    align-items:center;
    margin-left: 5px;
    
}
.header_left{
    flex:0.8;
    text-align: right;
}

.header_left p{
    font-size: 23px;
    margin-right: 22%;
    }
.header_right{
    padding:20px;
    flex:0.2;
    text-align: right;
}
.headerleft_options p{
    font-size: 15px;
}


@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px) {
    .header{
        flex-direction: column;
        justify-content: space-between;
    }
    .header_left{
        flex: 0.5;
    }
    .header_right{
        flex:0.5;
        padding:5px;
    }
    .header_left p{
        padding: 7px;
        font-size: 18px;
        text-align: none;
    margin-right: 0px;

    }
    .headerleft_options p{
        font-size: 14px;
    }

    .header_right>p{
        text-decoration: none;
    }
}
