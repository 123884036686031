*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  color:white
}

.app{
  background-color: #2E1760;
  height: auto;
  
}

.app img{
  object-fit: contain;
  display: block;
  margin: 0 auto;
  margin-bottom: 50px;
  
}
@media only screen 
and (min-device-width : 320px) 
and (max-device-width : 480px) {
  .app{
    width: 100%;
  }
  .app img{
    margin-left: 0px;
    width: 100%;
  }
}